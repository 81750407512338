<template>
  <div class="bottomContent">
    <div class="bottomw">
   <div class="bottomz">
         <div class="bottomL">
      <div class="lTitle">
        关于我们
        <!-- <img src="@/assets/home/logo.svg" alt=""> -->
      </div>
      <div class="lP">
         一家致力于软件研发的互联网科技公司，继承了研发与市场团队多年技术积累和市场经验，旗下拥有强大的AI 自然语言处理、图像处理和机器学习等技术的结合，实现了自动化、个性化和高效率的PPT生成过程，满足您的任意创作需求。
      </div>
    </div>

    <div class="bottomR">
        
      
      <div class="rTitle" v-if="isconfigforChannel?.ability?.used.comm.is_active =='1'">联系方式</div>
      <div class="rItem" v-if="isconfigforChannel?.ability?.used.comm.is_active =='1'&&  isconfigforChannel?.ability?.used.func.online_service_info !=='' &&  isconfigforChannel?.ability?.used.func.online_service_info !=='0'">
        <!-- <img src="@/assets/bottom/dh.png" alt=""> -->
        <span>在线客服：<a :href="isconfigforChannel?.ability?.used.func.online_service_info" style="color: #999999;font-size:16px">点击跳转</a></span>
        <span></span>
      </div>
      <div class="rItem" v-if="isconfigforChannel?.ability?.used.comm.is_active =='1'&&  isconfigforChannel?.ability?.used.func.customer_serv !==''&&  isconfigforChannel?.ability?.used.func.customer_serv !=='0'">
        <span>电话：{{isconfigforChannel?.ability?.used.func.customer_serv}}</span>
        <span></span>
      </div>
      <div class="rItem" v-if="isconfigforChannel?.ability?.used.comm.is_active =='1'&&isconfigforChannel?.ability?.used.func.corporation !== ''&&isconfigforChannel?.ability?.used.func.corporation !== '0'">
        <span>公司：{{isconfigforChannel?.ability?.used.func.corporation}}</span>
        <span></span>
      </div>
       <div class="rItem" v-if="isconfigforChannel?.ability?.used.comm.is_active =='1'&&isconfigforChannel?.ability?.used.func.location !== ''&&isconfigforChannel?.ability?.used.func.location !== '0'">
        <span>地址：{{isconfigforChannel?.ability?.used.func.location}}</span>
        <span></span>
      </div>
        
    </div>
   </div>


         <div class="threebtn">
       
      <div class="btnitem" v-if="isconfigforChannel?.standard_conf?.agreement_content?.comm.is_active =='1'&&isconfigforChannel?.standard_conf?.agreement_content?.func.user_agreement !== ''&&isconfigforChannel?.standard_conf?.agreement_content?.func.user_agreement !== '0'">
        <span><a :href="isconfigforChannel?.standard_conf?.agreement_content?.func.user_agreement" style="color: #999999;font-size:14px">《用户协议》、</a></span>
        <span></span>
      </div>
      <div class="btnitem" v-if="isconfigforChannel?.standard_conf?.agreement_content?.comm.is_active =='1'&&isconfigforChannel?.standard_conf?.agreement_content?.func.privacy_policy !== ''&&isconfigforChannel?.standard_conf?.agreement_content?.func.privacy_policy !== '0'">
        <span><a :href="isconfigforChannel?.standard_conf?.agreement_content?.func.privacy_policy" style="color: #999999;font-size:14px">《隐私政策》、</a></span>
        <span></span>
      </div>
      <div class="btnitem" v-if="isconfigforChannel?.standard_conf?.agreement_content?.comm.is_active =='1'&&isconfigforChannel?.standard_conf?.agreement_content?.func.refund_policy !== ''&&isconfigforChannel?.standard_conf?.agreement_content?.func.refund_policy !== '0' && isconfigforChannel?.standard_conf?.agreement_content?.func.refund_policy">
        <span><a :href="isconfigforChannel?.standard_conf?.agreement_content?.func.refund_policy" style="color: #999999;font-size:14px">《退款政策》</a> </span>
        <span></span>
      </div>
    </div>

   <!-- <div class="hx"></div> -->

  
      <div class="dbR" v-if="isconfigforChannel?.ability?.used.comm.is_active =='1'&&isconfigforChannel?.ability?.used?.func.bottom_content !==''&&isconfigforChannel?.ability?.used?.func.bottom_content !=='0'">
        Copyright © 2024 {{isconfigforChannel?.ability?.used.func.corporation}}   All Rights Reserved. {{ isconfigforChannel?.ability?.used?.func.bottom_content}}
      </div>
 
  </div>
  </div>
</template>

<script>
import { getspQuest } from '@/api/upload'
import { mapGetters} from 'vuex'
import assist from '@/utils/commonality';
export default {
  mixins: [],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
      ...mapGetters(['isconfigforChannel'])
  },
  watch: {},
  created() {
 
  },
  mounted() {
       this.getCfgForChannel()
   
  },
  methods: {
  async  getCfgForChannel() {
          this.$store.dispatch('getconfigforChannel')
          this.$nextTick(async () =>{

   const onlineServiceInfo = this.isconfigforChannel?.ability?.used?.func?.online_service_info;

      if (
        onlineServiceInfo === null ||
        onlineServiceInfo === undefined ||
        onlineServiceInfo == ''
      ) {
           await getspQuest(
              '/dingbot/send',
              'get',
              '35',
              {
                text:'没有配置在线客服',
                errno:1
              }
            )
      }


    const customer_serv = this.isconfigforChannel?.ability?.used?.func?.customer_serv;

      if (
        customer_serv === null ||
        customer_serv === undefined ||
        customer_serv == ''
      ) {
           await getspQuest(
              '/dingbot/send',
              'get',
              '35',
              {
                text:'没有配置电话',
                errno:1
              }
            )
      }


    const corporation = this.isconfigforChannel?.ability?.used?.func?.corporation;

      if (
        corporation === null ||
        corporation === undefined ||
        corporation == ''
      ) {
           await getspQuest(
              '/dingbot/send',
              'get',
              '35',
              {
                text:'没有配置公司',
                errno:1
              }
            )
      }



    const location = this.isconfigforChannel?.ability?.used?.func?.location;
      if (
        location === null ||
        location === undefined ||
        location == ''
      ) {
           await getspQuest(
              '/dingbot/send',
              'get',
              '35',
              {
                text:'没有配置地址',
                errno:1
              }
            )
      }



 const is_active = this.isconfigforChannel?.ability?.used?.comm?.is_active;


    if (
        is_active === null ||
        is_active === undefined ||
        is_active == ''
      )  {
        await getspQuest(
              '/dingbot/send',
              'get',
              '35',
              {
                text:'底部配置is_active为0',
                errno:0
              }
            )
      }
            
      
              
           
          })
    }
  },
};
</script>

<style scoped lang="scss">
.bottomContent {
  width: 100%;
  background-color: #000;

  min-width: 1080px;
}
.bottomw {
  width: 80%;
  margin: 0 auto;
  background-color: #000;
  padding-top: 30px;
 

  .bottomz {
display: flex;
  justify-content: space-between;
    color: #fff; 
    margin-bottom: 15px;
  }

  .bottomL {
      width: 45%;
    padding-bottom: 20px;
    .lTitle {
      font-size: 18px;
      margin-bottom: 15px;
    }
    .lP {
    
      color: #999999;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.1em;
    }
  }

  .bottomR {
    width: 30%;
    .rTitle {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .rItem {
      display: flex;
      align-items: center;
      margin: 15px 0;
      // max-width: 300px;
      font-size: 16px;
      img {
        width: 13px;
        height: 13px;
        display: block;
        margin-right: 10px;
      }
      span {
        font-size: 16px;
        color: #999999;
      }
    }
  }
}

.hx {
  width: 100%;
  height: 1px;
  background-color: #404040;
}

.db {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
  .dbL {
    display: flex;
    align-items: center;
  }
  .dbLItem {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #999999;
    span {
    font-size: 14px;
    }
    img {
      width: 24px;
      height: 28px;
      margin-right: 10px;
    }
  }

}
  .dbR {
    font-size: 14px;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #Fff;
  }


  
  .threebtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 12px;
  
    a {
      text-decoration: none;
      
    }
  }
</style>
