import request from '@/utils/axios'
import assist from '@/utils/commonality'





 export function getspQuest(path,method,business_id,params) { 
  return request({
    url: 'https://bucenter.proxy.sanwubeixin.cn/what',
    method: 'post',
    headers: {
      'Content-Type': 'application/json' // 根据需要设置请求头
    },
    data:JSON.stringify({
      encode:assist.encrypt({
        path: path,
        method: method,
        business_id: business_id,
        params: params,
      })
    }),
    
  })
 }


 export function getspQuest1(path,method,business_id,params) { 
  return request({
    url: 'https://bucenter.proxy.sanwubeixin.cn/what',
    method: 'post',
    headers: {
      'Content-Type': 'application/json' ,// 根据需要设置请求头
      "type": 0, 
      "is_trial": 0
    },
    data:JSON.stringify({
      encode:assist.encrypt({
        path: path,
        method: method,
        business_id: business_id,
        params: params,
      })
    }),
    
  })
 }
 //https://oss-shanghai.sanwubeixin.cn/yearCache/DomConvert/20240705/85134041/17201486556838615abf4896-d533-4ff9-ac52-b0df00e871c1.pdf


//https://proxydown.sanwubeixin.cn/download

